* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Verdana, sans-serif;

}
a {
  text-decoration: none;
  font-family: Arial, Verdana, sans-serif;
}

.Grid-container {
  column-gap: 10px;
  font-family: Arial, Verdana, sans-serif;
}
/* main {
  padding: 10px;
} */

.title {
  font-size: 3rem;
  display: grid;
  font-family: Arial, Verdana, sans-serif;
  place-items: center;
}
.main-titles {
  font-size: 15px;
  margin-left: 30px;
  /* color: rgb(0, 7, 61); */
  font-weight: 800;
  font-family: Arial, Verdana, sans-serif;
  color: white;
  margin-right: 30px;
}

.sub-titles {
  font-size: 15px;
  margin-left: 30px;
  font-weight: 700;
  margin-top: 10px;
  font-family: Arial, Verdana, sans-serif;
  color: white;
  margin-right: 30px;
}
.hackathon-Note {
  font-size: 15px;
  margin-left: 30px;
  font-weight: 700;
  font-family: Arial, Verdana, sans-serif;
  margin-top: 10px;
}
.details-texts {
  font-size: 15px;
  margin-left: 30px;
  font-weight: 400;
  margin-right: 30px;
  margin-top: 10px;
  font-family: Arial, Verdana, sans-serif;
  color: white;
  text-align: justify;
}
.details-textTops {
  font-size: 15px;
  margin-left: 30px;
  font-weight: 400;
  font-family: Arial, Verdana, sans-serif;
  margin-right: 30px;
  color: white;
  text-align: justify;
}
.containergrid {
  display: grid;
  font-family: Arial, Verdana, sans-serif;
}

.details-texttops {
  font-size: 15px;
  margin-left: 30px;
  font-weight: 400;
  color: white;
  font-family: Arial, Verdana, sans-serif;
  margin-right: 30px;
  text-align: justify;
}
.bullet-pointss {
  padding-left: 45px;
  padding-top: 6px;
  color: white;
  font-family: Arial, Verdana, sans-serif;
  margin-right: 30px;
}

.main-title {
  font-size: 15px;
  margin-left: 30px;
  color: #052a58;
  font-family: Arial, Verdana, sans-serif;
  font-weight: 800;
  margin-top: 30px;
  margin-right: 30px;
}
.main-titletheams {
  font-size: 15px;
  margin-left: 70px;
  font-weight: 400;
  font-family: Arial, Verdana, sans-serif;
  margin-right: 30px;
  margin-top: 10px;
  text-align: left;
}

.sub-title {
  font-size: 15px;
  margin-left: 40px;
  font-weight: 700;
  font-family: Arial, Verdana, sans-serif;
  margin-top: 10px;
  margin-right: 30px;
}
.details-text {
  font-size: 15px;
  margin-left: 40px;
  font-weight: 400;
  margin-right: 30px;
  font-family: Arial, Verdana, sans-serif;
  margin-top: 10px;
  text-align: justify;
}
.details-texttop {
  font-size: 15px;
  margin-left: 40px;
  font-family: Arial, Verdana, sans-serif;
  font-weight: 400;
  margin-right: 30px;
  text-align: left;
}
.bullet-points {
  padding-left: 45px;
  padding-top: 10px;
  font-family: Arial, Verdana, sans-serif;
  margin-right: 30px;
}

.bolded {
  font-size: 15px;
  font-weight: 700;
  font-family: Arial, Verdana, sans-serif;
  margin-top: 10px;
}

.main-container {
  display: flex;
  background-color: rgba(245, 247, 247, 255);
  background: white;
  width: 100%;
  padding: 2px;
  font-family: Arial, Verdana, sans-serif;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  position: sticky;
  top: 0;

  /* position:fixed */
}
.main-containersidebar {
  display: flex;
  background-color: rgba(245, 247, 247, 255);
  background: white;
  width: 100%;
  z-index: 1000;
  padding: 2px;
  font-family: Arial, Verdana, sans-serif;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  position: sticky;
  top: 0;
  gap: 3px;

  /* position:fixed */
}
.main-containers {
  display: flex;
  background-color: rgba(245, 247, 247, 255);
  background: rgba(245, 247, 247, 255);
  width: 100%;
  font-family: Arial, Verdana, sans-serif;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  position: sticky;
  top: 0;

  /* position:fixed */
}
/* Sidebar */
.sidebar {
  margin: auto;
  background: rgba(240, 244, 255, 255);
  border-radius: 10px;
  display: flex;
  width: 85%;
  font-family: Arial, Verdana, sans-serif;
  justify-content: center;
  align-items: center;
  position: sticky;
  justify-items: center;
  top: 0;
}
.routes {
  display: flex;
  flex-direction: row;
  width: 90%;
  font-family: Arial, Verdana, sans-serif;
  text-decoration: none;
  background-color: rgba(240, 244, 255, 255);
  justify-content: space-between;
  justify-items: center;

  gap: 5px;
}

.link {
  display: flex;
  /* color: white; */
  color: #052a58;
  font-family: Arial, Verdana, sans-serif;
  justify-content: center;
  gap: 20px;
  width: 80%;
  padding: 5px;
}
.top_section {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
  font-family: Arial, Verdana, sans-serif;
  justify-content: space-between;
  /* padding: 15px 10px; */
}
.logo {
  font-size: 18px;
  font-family: Arial, Verdana, sans-serif;
  line-height: 0;
}
.bars {
  width: 30px;
  font-family: Arial, Verdana, sans-serif;
}
.hide {
  display: none;
  font-family: Arial, Verdana, sans-serif;

}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-family: Arial, Verdana, sans-serif;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: white;
  font-family: Arial, Verdana, sans-serif;
  color: rgb(0, 7, 61);

  /* color: black; */
}

.links {
  display: flex;
  color: #052a58;
  justify-content: center;
  font-family: Arial, Verdana, sans-serif;
  width: 80%;
  padding: 5px;
}

.links:hover {
  background: #052a58;
  color: white;
  font-family: Arial, Verdana, sans-serif;
  text-decoration: none !important;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  background-color: #052a58;
  background: #052a58;
  color: white;
  font-family: Arial, Verdana, sans-serif;
  text-decoration: none !important;
  border-radius: 5px;

  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.csscolumn {
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  font-family: Arial, Verdana, sans-serif;
  grid-auto-flow: column;
  margin-left: "30px";
  margin-top: "30px";
  gap: 5;
}
.faq {
  justify-content: space-between;
  justify-items: center;
  background: "red";
  font-family: Arial, Verdana, sans-serif;
  background-color: #052a58;
  height: 90px;
}

.timelineimage {
  width: 90%;
  height: 80%;
  font-family: Arial, Verdana, sans-serif;
  margin-left: 30px;
  margin-top: 20px;
}
@media screen and (max-width: 1000px) {
  .csscolumn {
    column-count: 1;
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    font-family: Arial, Verdana, sans-serif;
  }
  .faq {
    height: auto;
    font-family: Arial, Verdana, sans-serif;
  }
  .timelineimage {
    width: 90%;
    height: 40%; 
     font-family: Arial, Verdana, sans-serif;

  }
  .imagecss {
    flex-basis: 100%;
    font-family: Arial, Verdana, sans-serif;
  }
  .sideBarBanner {
    display: flex;
    font-family: Arial, Verdana, sans-serif;
    grid-template-columns: 100%;
    padding-top: 10px;
    flex-direction: row;
  }
}
@media screen and (max-width: 900px) {
  .sideBarBanner {
    font-family: Arial, Verdana, sans-serif;
    grid-template-columns: 80% 20%;
    padding-top: 10px;
    flex-direction: row;
  }
  .csscolumn {
    column-count: 1;
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    font-family: Arial, Verdana, sans-serif;
  }
  .faq {
    height: auto;
    font-family: Arial, Verdana, sans-serif;

  }
  .timelineimage {
    width: 90%;
    height: 80%;
    font-family: Arial, Verdana, sans-serif;

  }
  
  .imagecss {
    flex-basis: 100%;
    font-family: Arial, Verdana, sans-serif;

  }
  #prize {
    max-width: 87%;
    font-family: Arial, Verdana, sans-serif;

  }
  
}
@media screen and (max-width: 800px) {
  .csscolumn {
    column-count: 1;
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    font-family: Arial, Verdana, sans-serif;

  }
  
  .faq {
    height: "80px";
    font-family: Arial, Verdana, sans-serif;

  }
  .imagecss {
    flex-basis: 100%;
    font-family: Arial, Verdana, sans-serif;

  }
  #prize {
    max-width: 87%;
    font-family: Arial, Verdana, sans-serif;

  }
}
@media screen and (max-width: 700px) {
  .csscolumn {
    column-count: 1;
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    font-family: Arial, Verdana, sans-serif;

  }
   .imagecss {
    flex-basis: 100%;
    font-family: Arial, Verdana, sans-serif;

  }
  #prize {
    max-width: 87%;
    font-family: Arial, Verdana, sans-serif;

  }
 
}

.gridprize {
  max-width: 100%;
  font-family: Arial, Verdana, sans-serif;

}
.active {
  border-right: #052a58;
  background: #052a58;
  color: white !important;;
  text-decoration: none !important;
  border-radius: 5px;
  font-family: Arial, Verdana, sans-serif;

}
span {
  grid-column-start: 1;
  font-family: Arial, Verdana, sans-serif;

}

.second-half {
  grid-column-start: 2;
  font-family: Arial, Verdana, sans-serif;

  /* extra styles */
}

.link_text {
  white-space: nowrap;
  font-family: Arial, Verdana, sans-serif;
  font-size: 15px;
  text-decoration: none !important;
  padding: 5px;
  border-radius: 5px;
  gap: 5px;
}
.link_texts {
  white-space: nowrap;
  font-size: 15px;
  font-family: Arial, Verdana, sans-serif;

  padding: 5px;
  border-radius: 5px;
  gap: 5px;
}
.container {
  height: 40px;
  position: relative;
  font-family: Arial, Verdana, sans-serif;

  border: 3px solid green;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  font-family: Arial, Verdana, sans-serif;

  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menu_container {
  display: flex;
  flex-direction: column;
  font-family: Arial, Verdana, sans-serif;

  justify-content: space-evenly;
  justify-items: center;
  flex-direction: row;
  background: white;
  background-color: white;
}
.menu_container .link {
  /* /* padding-left: 20px; */
  /* border-bottom: rgb(176, 178, 190) solid;  */
}

body {
  font-family: "Quicksand", sans-sans-serif;
  display: flex;
  justify-content: center;
  padding: 0;
  font-family: Arial, Verdana, sans-serif;

  color: #4d4d4d;
}
.imagecss {
  flex: 1 1 25%;
}
.sideBarBanner {
  display: grid;
  font-family: Arial, Verdana, sans-serif;
  grid-template-columns: 80% 20%;
  padding-top: 10px;
  flex-direction: column;
}
@media screen and (max-width: 800px) {
  #prize {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .csscolumn {
    column-count: 1;
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1;
  }
  .faq {
    height: "80px";
  }
  /* .imagecss {
    flex-basis: 100%;
  } */
  #prize {
    max-width: 90%;
  }
  .sideBarBanner{
    display:grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    padding-top: 10px;
  }
  .social{
    align-items: center;
  }
  .imagecss{
    margin-right: 5%;
  }
  .bannerimg{
    margin-left: 8%;
    width: 57%;
  }
}

@media only screen and (max-width:600px){
  .sideBarBanner{
    display:grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    padding-top: 10px;
  }
  .social{
    align-items: center;
  }
  .imagecss{
    margin-right: 5%;
  }
  .bannerimg{
    margin-left: 8%;
    width: 57%;
  }
  #prize {
    max-width: 90%;
  }
}
.prizescard {
  display: grid;
  font-family: Arial, Verdana, sans-serif;
  grid-template-columns: 49% 49%;
  gap: 2%;
  padding-right: 50px;
}
#title {
  text-align: center;
  font-family: Arial, Verdana, sans-serif;

}

#prize {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 95%;
  margin-left: 30px;
  font-family: Arial, Verdana, sans-serif;
  margin-top: 15px;
  margin-right: 40px;
  height: auto !important;
  height: 10px;
}

BoxInline {
  flex-direction: "row";
  display: "flex";
}
BoxText {
  display: 'flex';
  margin-top: '8px';
  
}
#prizeMain {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 90%;
  margin-left: 30px;
  font-family: Arial, Verdana, sans-serif;
  margin-top: 15px;
  height: auto !important;
  height: 10px;
}

#prizeMain td,
#prizeMain th {
  border: 1px solid #ddd;
  padding: 3px;
  font-family: Arial, Verdana, sans-serif;

}
#prize td,
#prize th {
  border: 1px solid #ddd;
  padding: 3px;
  font-family: Arial, Verdana, sans-serif;

}
#prizeMain td,
#prizeMain th {
  border: 1px solid #ddd;
  padding: 3px;
  font-family: Arial, Verdana, sans-serif;

}

#prize tr:hover {
  background-color: #ddd;
  padding: 1px;
  font-family: Arial, Verdana, sans-serif;

}
#prizeMain tr:hover {
  background-color: #ddd;
  padding: 1px;
  font-family: Arial, Verdana, sans-serif;

}

#prize th {
  padding: 5px;
  text-align: center;
  background-color: #052a58;
  color: white;
  font-family: Arial, Verdana, sans-serif;
  font-weight: 500;
  font-size: 12px;
}
#prizeMain th {
  padding: 5px;
  text-align: center;
  background-color: #052a58;
  color: white;
  font-family: Arial, Verdana, sans-serif;
  font-weight: 500;
  font-size: 12px;
}

.operation {
  text-align: center;
  font-family: Arial, Verdana, sans-serif;

}

/* .rs-uploader-trigger { display: none; } */

input[type="file"] {
  display: none;
}
